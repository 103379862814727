import React from 'react';
import Text from 'components/Text';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
// eslint-disable-line import/no-extraneous-dependencies
import { navigate } from 'gatsby';
import { Box } from 'grommet';
import { useMutation } from '@apollo/client';
import { flutterWavePaymentLink } from 'services/hasura';

const Spinner = styled.svg(() => [tw`animate-spin -ml-1 mr-3 h-14 w-14 text-purple-800 my-4`]);

export default function PesaPalPayment({ order }: { order: namespace.Order }) {
  const [getLink, { data, loading, error }] = useMutation(flutterWavePaymentLink, {
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
    if (loading) {
      return;
    }
    if (data && !error) {
      navigate(data.flutterwave.link);
    }
  }, [loading, data, error]);

  React.useEffect(() => {
    // console.log('get link', order);
    if (loading) return;
    try {
      if (order.uid) {
        getLink({ variables: { uid: order.uid } });
      }
    } catch (error) {
      console.log(error);
      navigate(`/booking/choose-payment?id=${order.id}`);
    }
  }, [getLink, order]);
  return (
    <Box>
      <Text label="You are being redirected to our payment partner" large />
      <Text label="Do not leave this page" small />
      <Spinner xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle
          css={css`
            ${tw`opacity-25`}
          `}
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          css={css`
            ${tw`opacity-75`}
          `}
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </Spinner>
    </Box>
  );
}
